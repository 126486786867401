import { getEnumValues } from '@gamepark/rules-api'

export enum Card {
  Forest = 1,
  Hills,
  Mountain,
  Peninsula,
  Lowland,
  River,
  Swamp,
  Valley,
  Woodlands,
  LascauxCave,
  Megaliths,
  Stonehenge,
  Australopithecus,
  Bear,
  Crocodile,
  CroMagnon,
  HomoErectus,
  HomoSapiens,
  Mammoth,
  Neanderthal,
  Tiger,
  WoollyRhinoceros,
  TheFire,
  FuneralRites,
  Wildcrafting,
  Fishing,
  Hunting,
  Sedentism,
  Tools,
  Weapons,
  Cannibals,
  Wolves,
  Earthquake,
  HarshWinter,
  Famine1,
  VolcanicEruption1,

  Agriculture = 101,
  AlexanderTheGreat,
  AlluvialPlain,
  Architecture,
  Aristotle,
  Astronomy,
  Attila,
  BarbarianInvasions,
  Bocage,
  CivilWar,
  Cleopatra,
  Coast,
  ColossusOfRhodes,
  Desert,
  Domestication,
  Epidemic,
  FertilePlain,
  Flood,
  GreatPyramidOfGiza,
  GreatWallOfChina,
  HangingGardensOfBabylon,
  Hannibal,
  Hippocrates,
  HorsebackRiding,
  IronWorking,
  JuliusCaesar,
  LighthouseOfAlexandria,
  MacedonianPhalanx,
  Mathematics,
  MausoleumOfHalicarnassus,
  Medicine,
  NebuchadnezzarII,
  Pericles,
  Philosophy,
  Piracy,
  Pompei,
  Pythagoras,
  RomanLegions,
  Sailing,
  SpartacusUprising,
  Famine2,
  StatueOfZeus,
  Steppes,
  SunTzu,
  TheWheel,
  VolcanicEruption2,
  WildIsland,
  Writing,

  Alchemy = 201,
  Alembic,
  Alhambra,
  AlKhawarizmi,
  Armor,
  Artillery,
  Astrolabe,
  Avicenna,
  Barony,
  BlackDeath,
  Camelot,
  Castle,
  Charlemagne,
  Chivalry,
  Cholera,
  Compass,
  County,
  Crossbow,
  Duchy,
  Famine3,
  Heresy,
  HimejiCastle,
  Jerusalem,
  JoanOfArc,
  KingArthur,
  Kingdom,
  LeCid,
  Longbow,
  Mangonel,
  MarcoPolo,
  Mill,
  MinamotoNoYoritomo,
  NotreDame,
  Obscurantism,
  Parchment,
  Plough,
  Poison,
  RichardTheLionheart,
  RobinHood,
  SaintThomasAquinas,
  Saladin,
  Saxons,
  TemplarOrder,
  TheCrusades,
  Tithe,
  TowerOfPisa,
  Vikings,
  WilliamTheConqueror,
  WilliamWallace
}

export const cards = getEnumValues(Card)
